@import url(https://fonts.googleapis.com/css?family=Crimson+Text);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-receta-sug {
  height: 90%;
  width: 100%;
  object-fit: cover;
}

.img-grid {
  height: 12.15em;
  width: 12.15em;
  object-fit: cover;
}

.asb {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.fixedScrollbtn {
  position: fixed;
  text-decoration: none;
  box-sizing: border-box;
  right: -40px;
  top: 35%;
  display: -webkit-flex;
  display: flex;
  z-index: 1;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.fixedScrollbtn p {
  color: #eb5919;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.img-receta {
  height: 348px;
  width: 100%;
  object-fit: cover;
}

.mgn-cultura {
  margin-left: 15%;
}

.background-queso {
  background: url(/static/media/MaridajeAndino.734656b6.jpg);
  background-position: left;
  background-size: cover;
}

.background-guardado {
  background-image: url(/static/media/GuardarAndino.fecf626d.jpg);
  background-position: left;
  background-size: cover;
}

.ver-btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.9rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tienda-logo {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: #fff;
  height: 170px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.cheeseImg {
  height: 324px;
  max-width: 486px;
  object-fit: cover;
}

.fav-img {
  height: 78px;
  width: 78px;
  object-fit: cover;
}

.quesos-back {
  text-align: left;
  background-color: #f7f3ec;
}

.cortar-img {
  max-width: 486px;
}

.recetas-img {
  object-fit: cover;
  height: 396px;
  max-width: 516px;
}

.img-cultura {
  width: 408px;
  height: 558px;
  object-fit: cover;
}

.pic-art {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.align-btn {
  text-align: center;
}

.img-aliado {
  max-width: 120px;
  margin: auto;
}

.row {
  padding: 0px !important;
  margin: 0px !important;
}

.event-text {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.event-title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 140%;
}

.input-filter {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-radius: 0px;
}

#root {
  background-color: #ffffff;
}

.bold-lat {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  text-transform: uppercase;
}

.sandfi3k9ujscas {
  width: 100%;
  margin: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: top;
          align-items: top;
  display: -webkit-flex;
  display: flex;
}

.asniw3eadas {
  width: 100%;
  padding-top: 20px;
}

.asdj9ejdascsnb3a p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  max-width: 487px;
  margin: auto;
  color: #ffffff;
}

.asdfasdif38ahsnbw p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  max-width: 324px;
}

.asdfasdif38ahsnbw button {
  width: 180px;
  height: 60px;
  background: #eb5919 !important;
  box-shadow: none !important;
  border-color: #eb5919 !important;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.asdfasdif38ahsnbw h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 45px;

  color: #ffffff;
}

.asdfasdif38ahsnbw {
  background-image: url(/static/media/texture4.facd28ba.png);
  background-color: black;
  max-width: 588px;
  margin: auto;
  padding: 5%;
  top: 150px;
  position: relative;
}

.asdn38hasn3zlc {
  margin-top: 150px;
}

.askjf3jc-bg {
  background-color: black;
}

.bg-theme-1 {
  background-color: #f7f3ec;
}

.slide-container {
  margin-top: -19px;
}

.asdj9ejdascsnb3a h1 {
  font-family: Crimson Text;
  font-style: italic;
  font-weight: normal;
  font-size: 4vw;
  line-height: 110%;
  padding-top: 32vh;
  position: absolute;
  z-index: 5;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;

  color: #ffffff;
}

.asniw3eadas a {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-right: 40px;
  margin-top: 12px;
  color: #ffffff !important;
  text-decoration: none;
}

.asdj9ejdascsnb3a {
}

.asd38sd-logo {
  height: 110px;
}

.asd82hasd0asz {
  margin-top: 120px;
}

.asd82hasd0asz p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  max-width: 788px;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
}

.asd82hasd0asz h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;

  text-align: center;

  color: #000000;
}

.asdn83nzs39sd p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  margin-top: 20px;
  color: #000000;
}

.asdm9q3enzc3 {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #eb5919;
}

.asdn83nzs39sd {
  background-color: #f7f3ec;
}

.asdn83nzs39sd h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 110%;
  /* or 53px */

  color: #000000;
}

.sdfjioe-3wda {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.nsdaf93ijsfsj {
  background-color: #f7f3ec;
  padding-top: 51px;
  padding-bottom: 50px;
}

.Próximoseventos {
  background-color: #f7f3ec;
  padding-bottom: 200px;
}

.Próximoseventos .col-md-4 {
  margin-top: 10px;
}

.Próximoseventos h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 91px;
  text-align: center;

  color: #000000;
  margin-bottom: 20px;
}

.sdfjioe-3wda h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
}

.sdfjioe-3wda a {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  color: #eb5919 !important;
}

.asdhfie392jsd {
  margin-right: 5%;
  border-left: 1px dashed #8b8b8b;
  padding-left: 2%;
}

.no-border {
  border: none !important;
}

.mr-20px {
  margin-left: 20px;
}

.nsdaf93ijsfsj img {
  width: 100%;
}

.nsdaf93ijsfsj {
  text-align: center;
}

.nsdaf93ijsfsj h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 91px;

  color: #000000;
}

.nsdaf93ijsfsj p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  max-width: 622px;
  margin: auto;
  margin-bottom: 0px;
}

.sadfjkeui34bxzc {
}

.sadfjkeui34bxzc img {
  height: 15em;
  width: 100%;
  object-fit: cover;
}

.sadfjkeui34bxzc h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: left;
  color: #000000;
}

.sadfjkeui34bxzc p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: left;

  color: #000000;
}

.max-wdiasd2 {
  max-width: 192px !important;
  background: #eb5919 !important;
  border-color: #eb5919 !important;
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  height: 50px;
  text-align: center;

  color: #ffffff !important;
  box-shadow: none !important;
}

.bg-white {
  background-color: white;
}

.sjklasdfjalas2,
.asndd83hasd {
  padding: 50px 10%;
}

.asndd83hasd h2::after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  margin-top: 4px;
  background-color: black;
}

.asndd83hasd h3 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;

  color: #000000;
}

.asndd83hasd h2 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  color: #000000;
}

.sad23uzbxcbwza {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  margin-left: 6%;

  color: #000000;
}

.asdn83ihasf2sds {
  font-family: Work Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-right: 50px;

  color: #000000;
}

.sjklasdfjalas2 h2 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-top: 30px;
  /* or 29px */

  color: #000000;
}

.asjfioj23adsd {
  background: #eb5919 !important;
  border-color: #eb5919 !important;
  max-width: 587px !important;
  width: 100%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 19px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff !important;
  box-shadow: none !important;
}

.buton-recipes {
  background: #eb5919 !important;
  border-color: #eb5919 !important;
  max-width: 587px !important;
  width: 120px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 19px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff !important;
  box-shadow: none !important;
}

.asfjidfjasdkoasp3 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.asfjidfjasdkoasp3 h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  /* or 24px */

  color: #ffffff;
}

.asfjidfjasdkoasp3 {
  background-image: linear-gradient(
      360deg,
      #000000 0%,
      rgba(196, 196, 196, 0) 100%
    ),
    var(--img);
  width: 100%;
  height: 282px;
  background-size: cover;
  color: white;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left !important;
}

.Recetas h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 91px;
  /* identical to box height */

  color: #000000;
}

.Recetas .adsf38sad {
  position: relative;
  top: -22%;
}

.Recetas p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  max-width: 484px;

  color: #000000;
}

.Recetas {
  margin-bottom: 150px;
}

.Recetas button,
.asdlfkj9asdasdxw2 {
  margin-bottom: 50px;
  background: #eb5919 !important;
  border-color: #eb5919 !important;
  box-shadow: none !important;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: white !important;
  text-align: center;
  width: 180px;
  height: 60px;
}

.ml-auto {
  margin-left: auto;
}

.Sad3i49sjdfsd {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  color: #a5a5a5;
}

.asdjasio3943jqasd p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;

  max-width: 375px;

  color: #000000;
}

.asjdwjqi8sn2 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  max-width: 375px;

  color: #000000;
}

.asdjasio3943jqasd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 500px;
  margin-left: 7.5%;
  border-left: 1px dashed #a5a5a5;
  padding-left: 25px;
}

.zmxzxqw8y {
  max-width: 190px !important;
}

.sec-blg {
  margin-top: 5%;
  margin-bottom: 5%;
}

.sec-blg p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  max-width: 792px;
  margin: auto;
  color: #000000;
}

.asdn32u8zbzkqw p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  max-width: 486px;
  color: #000000;
}

.asdn32u8zbzkqw span {
  font-weight: 900 !important;
}

.asdn32u8zbzkqw h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 110%;

  color: #000000;
}

.asdj83yhasd p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  max-width: 564px;
  margin: auto;
  color: #000000;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 5%;
}

.asdj83yhasd {
  background-color: white;
}

.asdfsidhr38snxqw {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px dashed #a5a5a5;
}

.asdjasio3943jqasd h2,
.asjdwjqi8sn2 h2 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */

  color: #000000;
  max-width: 298px;
}

.asdjasio3943jqasd spa span {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
  /* identical to box height, or 16px */

  color: #a5a5a5;
}

.asfhdsi3asd {
  max-width: 125px;
  max-height: 125px;
}

.frame-wrapper {
  display: inline-block;
  position: relative;
}

.frame-wrapper img {
  position: relative !important;
}

.frame-wrapper::before {
  content: "";
  display: block;
  z-index: 0;
  position: absolute;
  bottom: 5%;
  left: -5%;
  border: 4px solid black;
  background-color: black;
  height: 100%;
  width: 100%;
}

.frame-wrapper1 {
  display: inline-block;
  position: relative;
  float: right;
}

.frame-wrapper1 img {
  position: relative !important;
  margin-top: 10%;
}

.frame-wrapper1::before {
  content: "";
  display: block;
  z-index: 0;
  position: absolute;
  bottom: 0%;
  left: -10%;

  background-color: rgba(107, 107, 107, 0.08);
  height: 100%;
  width: 100%;
}

.sdfashu3wznqwv1 span {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.asndjuaez1 {
  max-width: 300px;
}

.asm9q3nsan20ass {
  max-width: 1100px;
  margin: auto;
}

.asdm3inszzasq9 {
  background-color: white;
  margin-top: 100px;
  padding-top: 40px;
}

.asdq290ejzxzq {
  margin-top: 25%;
}

.sdfashu3wznqwv1 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  max-width: 486px;

  color: #000000;
}

.sdfashu3wznqwv1 h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;

  color: #000000;
  max-width: 386px;
}

.frame-wrapper1::after {
  content: "";
  display: block;
  z-index: 0;
  position: absolute;
  bottom: -2%;
  left: -20%;

  height: 100%;
  width: 100%;
  background-image: url(/static/media/texture5.d1482d66.png);
}

.asdfjsdf112c {
  width: 100%;
  height: 5px;
  display: block;
  background-color: black;
  position: relative;
  margin-bottom: 10px;
}

.asdfjsdf112c1 {
  width: 90%;
  height: 5px;
  display: block;
  background-color: #000000;
  margin-bottom: 10px;
  position: relative;
  left: 3%;
  margin-bottom: 30px;
}

.asc12w8ash {
  width: 140px !important;
}

.sdfaiso39nncbs {
  position: relative;
  top: -20px;
  background-color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 39px 2px 10px;
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}

.asjdiow92e {
  margin-right: 13%;
}

.asjdiow92e1 {
  margin: auto !important;
}

.Nuestrasredes h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 91px;
  text-align: center;

  color: #000000;
}

.Nuestrasredes p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.Nuestrasredes span {
  color: #eb5919;
}

.Nuestrasredes {
  margin-bottom: 100px;
}

.mt-2p {
  margin-top: 5%;
}

.asdfsdhu3-asd {
  max-width: 1200px;
  margin: auto;
}

.Enalianzacon {
  background-color: #f7f3ec;
}

.Enalianzacon h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 91px;
  padding: 77px 0 21px 0;
  text-align: center;
  color: #000000;
}

.arrow-title {
  display: inline;
}

.asdj83ieawd2 {
  width: 180px;
  height: 180px;
  background-color: white;
  border-width: 0.5px;
  border-style: dotted;
  border-color: gainsboro;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sdsSdnadiaase2 {
  width: 98px;
  height: 98px;

  background: #c4c4c4;
}

.sadi3jsadjzczxcs {
  max-width: 1200px;
  margin: auto;
}

.sdajfojfsdfo3asd {
  list-style: none;
}

.sdajfojfsdfo3asd li {
  float: left;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.asdhffsd2 {
  font-weight: 600;
  color: #000000;
  text-decoration: none !important;
}

.asfdkfj3wwas {
  max-width: 486px;
  height: 324px;
  margin-left: auto;
  position: relative;
  left: 10%;
  top: 21px;
}

.container0asdajs {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
  margin-top: 20px;
}

/* Hide the browser's default checkbox */
.container0asdajs input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 16px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container0asdajs:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container0asdajs input:checked ~ .checkmark {
  background-color: #eb5919;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container0asdajs input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container0asdajs .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.page-2 {
  background-color: #f7f3ec;
}

.asdfji329nmc h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;
  text-align: center;
  margin: 47px 0px;

  color: #000000;
}

.sadiasndw29zc {
  max-width: 1107px;
  margin: auto;
  padding: 113px 0px;
}

.bg-black {
  background-color: black;
}

.bg-theme {
  background-color: #eb5919;
}

.asdjfkj39cnakwe {
  max-width: 486px;
  margin: 86px auto;
}

.asdjfkj39cnakwe p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.asdjfkj39cnakwe h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  color: #ffffff;
}

.asdsaih8i2mzn2 h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;
  /* or 67px */

  text-align: center;
  margin: 0px;
  color: #000000;
}

.asdsaih8i2mzn2 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}

.sadans8d923nsd span {
  font-weight: 900;
}

.sadans8d923nsd p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.sadans8d923nsd h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;

  color: #000000;
}

.asdsaih8i2mzn2 {
  padding-bottom: 150px;
}

.asdsaih8i2mzn2 .col-md-6,
.sadans8d923nsd .col-md-4 {
  max-width: 441px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.asdsaih8i2mzn2 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: left;
}

.asdsaih8i2mzn2 h2 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-left: 30px;

  color: #000000;
}

.dasfji93 {
  max-width: 700px;
  margin: auto;
  margin-bottom: 120px;
}

.dasfji93 h2 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  text-align: center;

  color: #000000;
}

.dasfji93 p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.saasc3rw3vfbfh {
  background-color: white;

  padding: 0px 5% 0px 10%;
}

.saasc3rw3vfbfh h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 121px;

  color: #000000;
}

.saasc3rw3vfbfh p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  max-width: 486px;

  color: #000000;
}

.saasc3rw3vfbfh p span {
  font-weight: 900;
}

.sadasd33vb {
  margin-left: 10px;
}

.suscribe-news button {
  width: 91px;
  height: 40px;
  background: #eb5919;
  text-align: center;
  color: #ffffff !important;
}

.suscribe-news-header {
  margin-left: 15%;
  margin-right: 12%;
  padding-top: 3%;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  color: #ffffff;
}

.suscribe-news {
  height: 250px;
  background-image: url(/static/media/texture4.facd28ba.png);
  background-color: black;
  max-width: 549px;
  margin-left: 8%;
  margin-right: 12%;
  padding: 0%;
}

.comprar-container button {
}

.comprar-container-header {
  margin-left: 15%;
  margin-right: 15%;
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 45px;
  color: #ffffff;
  text-align: left;
}

.comprar-container-subheader {
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 3%;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  text-align: left;
}

.comprar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 5%;
  height: 110%;
  background-image: url(/static/media/texture4.facd28ba.png);
  background-color: black;
  max-width: 100%;
  padding: 0%;
}

.margin-null {
  text-align: left !important;
  margin-left: 0% !important;
}

.suscribe-news-input {
  margin-left: 15%;
  margin-top: 3%;
}

.no-underline {
  text-decoration: none !important;
}

.white {
  color: "white" !important;
}

.share-float {
  position: absolute;
  margin-top: 20%;
  left: 101%;
}

.share-float-img {
  height: 27px;
  width: 27px;
}

.share-float-text {
  margin-left: 5px;
}

.share-float p {
  text-align: left;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.cultura-menu ul {
  list-style-type: none;
  margin-top: 3em;
  padding: 0;
  overflow: hidden;
}

.cultura-menu li {
  float: left;
}

.cultura-menu li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #000000;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.cultura-active {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #eb5919 !important;
}

.menu-follow-container {
  margin: auto !important;
}

.menu-follow {
  font-family: Work Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 140% !important;
  color: #fff !important;
  margin-left: 5% !important;
}

.menu-follow-icon {
  font-family: Work Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 140% !important;
}

.menu-li {
  margin-top: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, #eb5919 65%);
  display: inline;
}


.content-article {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

.content-article p {
  font-family: Work Sans;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 0% !important;
  margin-left: 0% !important;
  margin-right: 0% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.content-article h1 {
  font-family: Crimson Text;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 1.5% !important;
}

.content-article h2 {
  font-family: Crimson Text;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 1.5% !important;
}

.content-article h3 {
  font-family: Crimson Text;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 1.5% !important;
}

.content-article li {
  font-family: Work Sans;
}


.content-article img {
  padding-top: 0% !important;
  padding-bottom: 0% !important;
  margin-top: 1.5% !important;
  margin-bottom: 1.5% !important;
  text-align: center;
  max-width: 100%;
}

.next-container {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.social-align {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.h-evento {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 100% !important;
  color: #ffffff !important;
}

.footer-logos {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.sadihn22wsae2ewa {
  color: #000000 !important;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-right: 80%;
  margin-top: 80%;
}

.stickyBar {
  z-index: 100 !important;
}

.clicklable:hover {
  cursor: pointer;
}

.background-one {
  background: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)),
    url(/static/media/01_Colombia_1920.51e90ccc.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-two {
  background: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)),
    url(/static/media/03_Ecuador_1920.16a24f71.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-three {
  background: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)),
    url(/static/media/02_Peru_1920.223cdd56.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .asdj9ejdascsnb3a {
    padding-bottom: 106px;
  }
}

.asdfhisdl {
  position: relative;
  z-index: 1;
  padding-top: 5%;
}

@media screen and (max-width: 991px) {
  .asd38sd-logo {
    margin-top: 6px;
  }

  .page-2 .container {
    max-width: 1000px !important;
  }

  .asdf23iasd121 {
    max-width: 1000px !important;
  }
}

@media screen and (max-width: 1024px) {
  .sadiasndw29zc img {
    width: 100%;
  }

  .sdfjioe-3wda {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .asdhfie392jsd {
    margin-bottom: 20px;
  }
}

.asd93jeaf3scxz {
  padding: 0px 10px;
}

@media screen and (max-width: 1400px) {
  .img-grid {
    height: 11.3em;
    width: 11.3em;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .social-align {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .asdfjsdf112c {
    left: 0%;
  }

  .buton-recipes {
    width: 255px;
  }

  .next-container {
    margin-top: 3em;
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .Próximoseventos {
    padding-bottom: 200px;
  }

  .asdq290ejzxzq {
    margin-top: 10%;
  }

  .suscribe-news {
    margin-left: 0%;
    margin-right: 0%;
  }

  .img-alig {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .fixedScrollbtn {
    position: fixed;
    text-decoration: none;
    box-sizing: border-box;
    right: -40px;
    top: 35%;
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .asdj9ejdascsnb3a h1 {
    font-family: Crimson Text;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 94.5%;
    text-align: center;
  }

  .asdfsdhu3-asd {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .page-2 .justify-content-center {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .asfdkfj3wwas {
    left: 0%;
    width: 100%;
  }
}

.asdfofjowemcn {
  margin: 0px 0px;
}

.asdfsadojfisf-sdff {
  list-style: none;
  padding: 0px 50px;
  margin-top: 10% !important;
}

.sam039ojascf {
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.asdfb382ashndsf {
  width: 90%;
  background-color: white;
  height: 2px;
  margin: auto;
}

.asdfsadojfisf-sdff a {
  text-decoration: none;
  color: white;
}

.asdfsadojfisf-sdff li {
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.asdfofjowemcn a {
  text-decoration: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;

  color: #000000;
}

@media screen and (max-width: 568px) {
  .background-one {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/01_Colombia_375.a578b053.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .background-two {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/03_Ecuador_375.5510fdee.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .background-three {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/02_Peru_375.51734150.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footer-logos {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }

  .suscribe-news-input {
    margin-right: 10% !important;
  }

  .image-grid {
    height: 18em !important;
    object-fit: "cover" !important;
  }

  .asjdiow92e {
    margin-right: 5%;
    margin-bottom: 5%;
  }

  .asdjasio3943jqasd {
    padding-left: 14px;
  }

  .share-float {
    display: none;
  }

  .resp-head {
    line-height: 120% !important;
    margin-bottom: 10%;
  }

  .asdhfie392jsd {
    -webkit-flex: 100% 1;
            flex: 100% 1;
    margin-left: 10%;
  }

  .fixedScrollbtn {
    display: none;
  }

  .mgn-cultura {
    margin-left: 1%;
    margin-bottom: 15%;
  }

  .asdjasio3943jqasd {
    margin-left: 1%;
  }

  .sdfjioe-3wda {
    border-width: 1px;
    border-style: dashed;
    border-color: gray;
    padding-top: 15px;
  }
}

@media screen and (max-width: 425px) {
  .img-cultura {
    width: 100%;
    height: 369px;
    object-fit: cover;
  }

  .sadfjkeui34bxzc img {
    height: 10em;
    width: 100%;
    object-fit: cover;
  }

  .img-aliado {
    max-width: 80px;
  }

  .sadi3jsadjzczxcs {
    max-width: 100%;
  }

  .asdj83ieawd2 {
    width: 97px;
    height: 97px;
    padding: 2px;
  }

  .asdfj329ajsf {
    display: -webkit-flex;
    display: flex;
    margin-left: 5px;
  }

  .asdfj93uwre {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .asdfj93uwre img {
    margin: 0px !important;
  }

  .asdni83en23sd {
    -webkit-flex: 100% 1;
            flex: 100% 1;
    text-align: center;
  }

  .asfjsi3ie9asmbe {
    margin: 15px auto;
  }

  .asdfnu328scnsmw {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }

  .dansi2as0e,
  .dansi2as0e-1 {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center !important;
  }

  .asdhfie392jsd img {
    display: none;
  }

  .frame-wrapper1 {
    margin-top: 5%;
    margin-left: 15%;
  }

  .asjdwjqi8sn2 {
    margin-right: 0px !important;
  }

  .sadfjkeui34bxzc {
    margin-top: 40px;
  }

  .dasfji93 {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .asdhfie392jsd {
    border: none;
  }

  .sdfjioe-3wda {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
}

.scrolasdfji3waf {
  top: 0;
  background: black;
  z-index: 1;
  width: 100%;
  left: 0%;
}

.asdfnu328scnsmw a {
  font-family: Work Sans;
  font-style: normal;
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 250%;
  text-decoration: none;
  /* identical to box height, or 40px */

  text-transform: uppercase;

  color: #ffffff !important;
}

.asdfnu328scnsmw p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  max-width: 392px;
  color: #ffffff;
}

.asdfnu328scnsmw h1 {
  font-family: Crimson Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 45px;

  color: #ffffff;
}

.askdin3e0asddk23 {
  background-color: black;
}

.asfn3iisf3sadk2 {
  width: 260px;
  margin: 61px 0px;
}

.b-1asd22 {
  width: 100%;
  background-color: white;
  height: 2px;
}

.scrolasdfji3waf .asniw3eadas {
  -webkit-justify-content: center !important;
          justify-content: center !important;
  max-width: 100%;
  margin-bottom: 20px;
}

.dansi2as0e {
  height: 68px;
  background-image: url(/static/media/cniel.9e893327.svg);
  max-width: 362px;
  width: 100%;
}

.dansi2as0e-1 {
  max-width: 107px;
  height: 200px;
  background-image: url(/static/media/Logo_Enjoy.8cf05adc.svg);
  background-size: cover;
  width: 100%;
}

.asdfnu328scnsmw {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 80px;
}

.afdsf23as9sdf23esd {
  padding-top: 124px;
}

.asfjsi3ie9asmbe {
  max-width: 295px;
  margin-bottom: 15px;
  background-color: white !important;
}

.asfiejq3mdasadf3-asfd {
  height: 40px;
  background: #eb5919 !important;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 40px !important;
  color: #ffffff !important;
}

.asdfj9w3ie0awj3n {
  background-color: #eb5919;
  width: 41px;
  height: 41px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 41px;
}

.asdfop23leas-d {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 120%;
  max-width: 640px;
  text-align: right;
  color: #ffffff;
  position: relative;
}

.asmdi21mamd12 {
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 299.8%;
  /* identical to box height, or 48px */

  text-align: center;

  color: #ffffff;
}

.asmdi21mamd12 span {
  text-decoration: underline;
}

